import { render, staticRenderFns } from "./SpRadio.vue?vue&type=template&id=2f8d4353&scoped=true"
import script from "./SpRadio.vue?vue&type=script&lang=js"
export * from "./SpRadio.vue?vue&type=script&lang=js"
import style0 from "./SpRadio.vue?vue&type=style&index=0&id=2f8d4353&prod&lang=scss"
import style1 from "./SpRadio.vue?vue&type=style&index=1&id=2f8d4353&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f8d4353",
  null
  
)

export default component.exports