import { render, staticRenderFns } from "./SpTabbedPanel.vue?vue&type=template&id=764e0882&scoped=true"
import script from "./SpTabbedPanel.vue?vue&type=script&lang=js"
export * from "./SpTabbedPanel.vue?vue&type=script&lang=js"
import style0 from "./SpTabbedPanel.vue?vue&type=style&index=0&id=764e0882&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764e0882",
  null
  
)

export default component.exports